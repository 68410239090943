<template>
  <Layout>
    <div class="main-material">
      <div class="base-width">
        <Search v-ani.fade-up placeholder="请输入关键词搜索" :type="false" @change="handleSearch" />
      </div>
      <div class="c-layout-2">
        <div class="base-width">
          <div class="c-layout-2__lf">
            <!-- 分类 -->
            <Cate :list="cateList" curId="1" path='/news' v-ani.fade-right />
          </div>
          <div class="c-layout-2__main" :key="$route.query.id">
            <!-- 招标项目情况 -->
            <HomeNews2 v-ani.fade-left :data="biddingBase" />
          </div>
        </div>
      </div>
      <div class="base-width">
        <!-- 分类 -->
        <CateCarousel @change="changeCat"></CateCarousel>
        <!-- 参数 -->
        <Params :baseObj="params" @change="changeStateRegion" v-ani.fade-up type="bid" />
        <!-- <ProductParams :collapse='false' :params="params" @change="handleChange" v-ani.fade-up /> -->
        <!-- 最新招投标-列表 -->
        <BidList @showCode='showCode = true' :list="biddingData" :msgList="msgList" type="Material"></BidList>
        <!-- 分页 -->
        <Pagination
          :page="pager.page"
          :size="pager.size"
          :total="pager.total"
          v-ani.fade-up
        />
      </div>
    </div>
    <!-- 二维码弹窗 -->
    <BasePopCode :show.sync="showCode"></BasePopCode>
  </Layout>
</template>

<script>
import { biddingBaseApi, biddingListApi, biddingMsgListApi, biddingCatListApi, biddingAreaListApi, biddingStateListApi } from "@/request/api/bid";
export default {
  data() {
    return {
      cateList: [
        { cat_id: '', cat_name: '招投标', link: '/bid/bid' },
        { cat_id: '1', cat_name: '招采大厅', link: '/bid/material' },
      ],
      params: {},
      pager: {
        page: 1,
        page_num: 10,
      },
      keywords: "",
      total: 0,
      showCode: false,
      biddingBase: [],
      biddingData: [],
      msgList: [],
      areaList: [],
      cat_id: "",
      city: 0,
      tender_status: ""
    };
  },
  async mounted(){
    this.getBiddingBase();
    this.biddingList();
    this.biddingMsgList();
    await this.biddingAreaList();
    await this.biddingStateList();
    // this.biddingCatList();
  },
  methods: {
    handleSearch(e) {
      this.keywords = e;
      this.biddingList();
    },
    handleChange() {},
    changeCat(id){
      this.cat_id = id;
      this.biddingList();
    },
    //招标项目情况
    getBiddingBase(){
      biddingBaseApi().then(res => {
        if(res.status == 200){
          this.biddingBase = res.data.project_situation;
          this.biddingBase = this.biddingBase.map(item => {
            item.article = item.list;
            delete item.list;
            return item;
          })
        }
      })
    },
    //最新招投标
    biddingList(){
      let params = {
        ...this.pager,
        cat_id: this.cat_id,
        keywords: this.keywords,
        city: this.city,
        tender_status: this.tender_status
      }
      biddingListApi(params).then(res => {
        if(res.status == 200){
          this.biddingData = res.data.list;
          this.total = res.data.count;
        }
      })
    },
    //消息列表
    biddingMsgList(){
      biddingMsgListApi().then(res => {
        if(res.status == 200){
          this.msgList = res.data
        }
      })
    },
    //地区
    async biddingAreaList(){
      await biddingAreaListApi().then(res => {
        if(res.status == 200){
          this.$set(this.params, "region", res.data);
        }
      })
    },
    //状态
    async biddingStateList(){
      await biddingStateListApi().then(res => {
        if(res.status == 200){
          this.$set(this.params, "state", res.data);
        }
      })
    },
    changeStateRegion(obj){
      if(obj.typeNum == 1){
        this.city = obj.val.id;
      }else{
        this.tender_status = obj.val.title;
      }
      this.biddingList();
    }
  }
};
</script>

<style lang="scss" scoped>
.c-prod-params{
  margin-top: .41rem;
  padding-top: .15rem;
  ::v-deep{
    .item{
      padding-top: .15rem;
      padding-bottom: .15rem;
    }
    .item .list .li{
      line-height: .4rem;
      margin-top: 0; margin-bottom: 0; 
    }
    .item .list .li.on{
      font-size: .18rem;
    }
  }
}
.md-pagination{
  margin: .6rem 0;
}
</style>